import { cn } from '@bem-react/classname'
import { FC } from 'react'
import { NavLink } from 'react-router-dom'

import './Logo.scss'

interface ILogo {
  alt?: string
  className?: string
  type?: 'link' | 'img'
  to?: string
  src?: string
}

const cnLogo = cn('Logo')

export const Logo: FC<ILogo> = ({
  className,
  type = 'link',
  alt = '',
  to = '/',
  src = '/img/logov2.svg',
}) => {
  return (
    <>
      {type === 'link' ? (
        <NavLink to={to} className={cnLogo(null, [className])}>
          <img src={src} alt={alt} />
        </NavLink>
      ) : (
        <img src={src} alt={alt} className={cnLogo(null, [className])} />
      )}
    </>
  )
}
